import {Table} from 'react-bootstrap';
import { Trash2 } from 'react-feather';

const ItemsTable = (props) => {
  return (
    <Table responsive hover size="sm">
        <thead  className="align-middle">
            <tr>
                {
                    props.theads.map(thead => 
                        <th scope="col" className="text-bold fw-normal fs-7 text-nowrap p-3">{thead}</th>
                    )
                }
            </tr>
        </thead>
        <tbody className="align-middle">
        {
            props.data.map(element =>  
                <tr key={element._id} id={element._id}>
                    <td  className="text-nowrap p-3">{element.item}</td>
                    <td>
                        <Trash2 color='#ee3030' size={18} className='mx-2' onClick={() => props.deleteItem(element._id)} />
                    </td>
                </tr>
            )
        }
        </tbody>
    </Table>
  )
}

export default ItemsTable