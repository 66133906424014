import { Home } from 'react-feather';
import { Link } from 'react-router-dom';

const Breadcrumb = (props) => {
  return (
    <div style={{color: '#aaa', fontSize: "13px"}} className='d-flex justify-content-start align-items-center p-1 my-2'>
        <Link to="/"><Home color="#aaa" size={16} /></Link> / {props.title}
    </div>
  )
}

export default Breadcrumb