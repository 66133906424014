import { Archive } from "react-feather"

const NoItemFound = () => {
  return (
    <>
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <Archive color="#ddd" size={128} />
            <div className="text-bold fs-5" style={{color: '#ddd'}}>
                متاسفانه آیتمی یافت نشد!
            </div>
        </div>
    </>
  )
}

export default NoItemFound