import {Table} from 'react-bootstrap';
import { Trash2, Edit, Eye } from 'react-feather';
import BaseUrl from '../Config'

const UsersTable = (props) => {
  return (
    <Table responsive hover size="sm">
        <thead  className="align-middle">
            <tr>
                {
                    props.theads.map(thead => 
                        <th scope="col" className="text-bold fw-normal fs-7 text-nowrap p-3">{thead}</th>
                    )
                }
            </tr>
        </thead>
        <tbody className="align-middle">
        {
            props.data.map(element =>  
                <tr key={element._id} id={element._id}>
                    <td  className="text-nowrap p-3">
                    {element.avatar && element.avatar.length !== 0  ? 
                    <img src={`${BaseUrl}/../${element.avatar.path}/${element.avatar.filename}`} alt={element.avatar._id} className="d-block rounded" width={60} height={60} />
                    : "no Image"}    
                    </td>
                    <td  className="text-nowrap p-3">{element.title}</td>
                    <td  className="text-nowrap p-3">{element.firstName}</td>
                    <td  className="text-nowrap p-3">{element.lastName}</td>
                    <td  className="text-nowrap p-3">{element.mobile}</td>
                    <td  className="text-nowrap p-3">{element.address}</td>
                    <td  className="text-nowrap p-3">{element.status ? "فعال" : "غیرفعال"}</td>
                    <td>
                        <Eye size={18} className='mx-2' onClick={() => props.deleteItem(element._id)} />
                        <Edit color='blue' size={18} className='mx-2' onClick={() => props.editItem(element._id)} />
                        <Trash2 color='#ee3030' size={18} className='mx-2' onClick={() => props.deleteItem(element._id)} />
                    </td>
                </tr>
            )
        }
        </tbody>
    </Table>
  )
}

export default UsersTable