import axios from "axios"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Breadcrumb from "../Components/Breadcrumb"
import NoItemFound from "../Components/NoItemFound"
import Error from "../Components/Error"
import {useState, useEffect} from "react"
import {Col, Button, Modal, Table, Row, Carousel } from 'react-bootstrap';
import { Edit, Trash2, Package, Image, Folder, Plus, Tag, Filter, Zap, AlertTriangle, Hash, Eye, XCircle } from 'react-feather';
import BaseUrl from '../Config';
import LoadingTable from "../Components/LoadingTable"


const Products = (props) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const [show, setShow] = useState(false);
  const [showSingle, setShowSingle] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteErr, setDeleteErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [productError, setProductError] = useState(false);
  const [editProduct, setEditProduct] = useState(false);
  const [orderTypes, setOrderTypes] = useState(null);
  const [colors, setColors] = useState(null);
  const [models, setModels] = useState(null);
  const [sizes, setSizes] = useState(null);
  const [categories, setCategories] = useState(null);
  const [productId, setProductId] = useState(null);
  const [productName, setProductName] = useState(null);
  const [productCode, setProductCode] = useState(null);
  const [productType, setProductType] = useState(null);
  const [productCat, setProductCat] = useState(null);
  const [productDesc, setProductDesc] = useState(null);
  const [productModel, setProductModel] = useState(null);
  const [productImages, setProductImages] = useState(null);
  const [productVariation, setProductVariations] = useState(null);
  const [addForm, setAddForm] = useState(false);
  const [addError, setAddError] = useState(false);

    const addNewForm  = () => {
      setAddForm(true);
      setAddError(false);
      getOrderTypes();
      getCategories();
      getModels();
    };

    const closeAddForm  = () => {
      setAddForm(false);
      setAddError(false)
    };

    const handleClose = () => {
      setShow(false);
      setItemToDelete(null);
      setDeleteErr(false);
    };
    const handleShow = (id) => {
      setShow(true);
      setItemToDelete(id);
    }
    const handleProductClose = () => {
      setShowSingle(false);
      setEditProduct(false);
      setProduct(null);
      setProductId(null);
      setProductName(null)
      setProductCode(null)
      setProductType(null)
      setProductCat(null)
      setProductDesc(null)
      setProductModel(null)
      setProductImages(null)
      setProductVariations(null)
    };
    const handleProductShow = async (id) => {
      setShowSingle(true);
      await axios.get(`${BaseUrl}/products/${id}`)
      .then((response) => {
        setProduct(response.data.response);
        setProductError(false)
        console.log(response);
      })
      .catch((error) => {
        setProductError(true)
      });
    };

    const getOrderTypes = async () => {
      await axios.get(`${BaseUrl}/ordertypes`)
      .then((response) => {
        setOrderTypes(response.data.response);
      })
      .catch((error) => {
        setProductError(true)
      });
    };

    const getColors = async () => {
      await axios.get(`${BaseUrl}/colors`)
      .then((response) => {
        setColors(response.data.response);
      })
      .catch((error) => {
        setProductError(true)
      });
    };

    const getSizes = async () => {
      await axios.get(`${BaseUrl}/sizes`)
      .then((response) => {
        setSizes(response.data.response);
      })
      .catch((error) => {
        setProductError(true)
      });
    };

    const getModels = async () => {
      await axios.get(`${BaseUrl}/models`)
      .then((response) => {
        setModels(response.data.response);
      })
      .catch((error) => {
        setProductError(true)
      });
    };

    const getCategories = async () => {
      await axios.get(`${BaseUrl}/categories`)
      .then((response) => {
        setCategories(response.data.response);
      })
      .catch((error) => {
        setProductError(true)
      });
    };

    const handleProductEdit = async (id) => {
      setEditProduct(true);
      await axios.get(`${BaseUrl}/products/${id}`)
      .then((response) => {
        setProduct(response.data.response);
        setProductId(response.data.response._id);
        setProductName(response.data.response.title)
        setProductCode(response.data.response.code)
        setProductType(response.data.response.type)
        setProductCat(response.data.response.category)
        setProductDesc(response.data.response.description)
        setProductModel(response.data.response.model._id)
        setProductImages(response.data.response.images)
        setProductVariations(response.data.response.variety)
        setProductError(false)
        console.log(response);
      })
      .catch((error) => {
        setProductError(true)
      });

      getOrderTypes();
      getColors();
      getSizes();
      getModels();
      getCategories();
      
    }
    const deleteItem = async () => {
      setLoading(true);
      await axios.delete(`${BaseUrl}/products/${itemToDelete}`)
      .then (response => {
        setItemToDelete(null);
        setLoading(false);
        setShow(false);
      })
      .catch (err => {
        setLoading(false);
        setDeleteErr(true);
      });
    };
    const deleteImage = async (id) => {
      await axios.put(`${BaseUrl}/products/${productId}/${id}`)
      .then (response => {
        handleProductEdit(productId);
        //console.log('Deleted successfully!');
      })
      .catch (err => {
        //console.log('Error deleting Image');
      });
    };
    const getProducts = async (id) => {
      axios.get(`${BaseUrl}/products`)
      .then(response => {
        setData(response.data.response)
        setError(false)
      })
      .catch(err => {
        setData(null)
        setError(true)
      });
    };

    
    const updateProductDetails = async (event)  => {
      const dataToUpdate = {
        title: productName,
        code: productCode,
        type: productType,
        category: productCat,
        description: productDesc,
        model: productModel,
        variety: productVariation
      };
      
      await axios.put(`${BaseUrl}/products/${productId}`, dataToUpdate)
      .then(response => {
        console.log(response);
        handleProductClose();
      })
      .catch(err => {
        console.error(err);
      });

    };
    const photoUpload = async () => {
        const x = document.getElementById('photo');
        console.log(x.files[0].name);
        console.log(x.files[0].size);
        document.getElementById('progressParent').classList.remove('d-none');
        document.getElementById('uploadProgress').classList.add('w-25', 'progress-bar-striped', 'progress-bar-animated');
        document.getElementById('uploadProgress').classList.remove('w-100', 'bg-success');
        document.getElementById('uploadError').classList.add('d-none');
        var bodyFormData = new FormData();
        bodyFormData.append('photo', x.files[0]);
        await axios({
          method: "post",
          url: `${BaseUrl}/images/new`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(async (response) => {
          //handle success
          console.log(response.data.response._id);
          document.getElementById('uploadProgress').classList.remove('w-25');
          document.getElementById('uploadProgress').classList.add('w-75');

  
          await axios.patch(`${BaseUrl}/products/updateimage/${productId}`, {image: response.data.response._id})
          .then (results => {
            document.getElementById('uploadProgress').classList.remove('w-75');
            document.getElementById('uploadProgress').classList.add('w-100');
            document.getElementById('uploadProgress').classList.add('bg-success');
            document.getElementById('uploadProgress').classList.remove('progress-bar-striped', 'progress-bar-animated');
            setTimeout(() => {
              document.getElementById('progressParent').classList.add('d-none');
            }, 2000);
            handleProductEdit(productId);
          })
          .catch(err => {
            document.getElementById('progressParent').classList.add('d-none');
            document.getElementById('uploadError').classList.remove('d-none');
          });
        })
        .catch((response) => {
          document.getElementById('progressParent').classList.add('d-none');
            document.getElementById('uploadError').classList.remove('d-none');
        });
    };
    const deleteVariation = (variationId) => {
      document.getElementById(variationId).classList.add('hidden');
      let varietyToDelete = productVariation.map(item => item._id).indexOf(variationId);
      ~varietyToDelete && productVariation.splice(varietyToDelete, 1);
    };
    const addVariation = async () => {
      const colorVariation = document.getElementById('colorVariation');
      const sizeVariation = document.getElementById('sizeVariation');
      const stockVariation = document.getElementById('stockVariation');
      if (colorVariation.value && sizeVariation.value && stockVariation.value) {
        const newVariation = {
          "color": colorVariation.value,
          "size": sizeVariation.value,
          "inStock": stockVariation.value
        };

        const newVarietyDiv = document.getElementById('newVariation');

        const div = document.createElement("div");
        newVarietyDiv.appendChild(div);
        div.classList.add('d-flex', 'flex-row', 'justify-content-around', 'align-items-center', 'p-2');
        
        const firstCol = document.createElement("div");
        div.appendChild(firstCol);
        firstCol.classList.add('d-flex', 'flex-column', 'p-1');

        const secondCol = document.createElement("div");
        div.appendChild(secondCol);
        secondCol.classList.add('d-flex', 'flex-column', 'p-1');

        const thirdCol = document.createElement("div");
        div.appendChild(thirdCol);
        thirdCol.classList.add('d-flex', 'flex-column', 'p-1');

        const firstColLabel = document.createElement("label");
        firstColLabel.innerText = "رنگ: ";
        firstCol.appendChild(firstColLabel);

        const secondColLabel = document.createElement("label");
        secondColLabel.innerText = "سایز: ";
        secondCol.appendChild(secondColLabel);

        const thirdColLabel = document.createElement("label");
        thirdColLabel.innerText = "موجودی انبار: ";
        thirdCol.appendChild(thirdColLabel);

        const colorInput = document.createElement("input");
        colorInput.type = "text";
        colorInput.value = colorVariation.selectedOptions[0].innerText;
        colorInput.classList.add("form-control", "form-control-sm");
        firstCol.appendChild(colorInput);

        const sizeInput = document.createElement("input");
        sizeInput.type = "text";
        sizeInput.value = sizeVariation.selectedOptions[0].innerText;
        sizeInput.classList.add("form-control", "form-control-sm");
        secondCol.appendChild(sizeInput);

        const stockInput = document.createElement("input");
        stockInput.type = "text";
        stockInput.value = stockVariation.value;
        stockInput.classList.add("form-control", "form-control-sm");
        thirdCol.appendChild(stockInput);

        productVariation.push(newVariation);
        

      } else {
        !colorVariation.value ? colorVariation.classList.add('border-danger') : colorVariation.classList.remove('border-danger');
        !sizeVariation.value ? sizeVariation.classList.add('border-danger') : sizeVariation.classList.remove('border-danger');
        !stockVariation.value ? stockVariation.classList.add('border-danger') : stockVariation.classList.remove('border-danger');
      }

    };
    const addProductOp = async () => {
      const productCode = document.querySelector('#productCode');
      const productTitle = document.querySelector('#productTitle');
      const productDescription = document.querySelector('#productDescription');

      if (!productCode.value) {
        productCode.classList.add('border-danger');
      } else {
        productCode.classList.remove('border-danger');
        productCode.classList.add('border-success');
      }

      if (!productTitle.value) {
        productTitle.classList.add('border-danger');
      } else {
        productTitle.classList.remove('border-danger');
        productTitle.classList.add('border-success');
      }

      if (!productDescription.value) {
        productDescription.classList.add('border-danger');
      } else {
        productDescription.classList.remove('border-danger');
        productDescription.classList.add('border-success');
      }

      if (productTitle.value && productCode.value && productDescription.value) {
        const mainOrder = orderTypes[0]._id;
        const mainCat = categories[0]._id;
        const mainModel = models[0]._id;
        const newProductDetails = JSON.stringify({code: productCode.value, title: productTitle.value, description: productDescription.value, type: mainOrder, category: mainCat, model: mainModel});
        const headers = {
          headers: {
          'Content-Type': 'application/json'
          }
      };
        const url = `${BaseUrl}/products/new`;
        await axios.post(url, newProductDetails, headers)
        .then (response => {
          console.log(response);
          handleProductEdit(response.data.response._id);
          setAddForm(false);
        })
        .catch (err => {
          setAddError(true);
          console.log(err);
        });
      }
      
    };
  useEffect(() => {
    getProducts();
  }, [data]);
  return (
    <div>
      {/* Add New Product */}
        {addForm ?
          <>
            <Modal show={addForm} onHide={closeAddForm} backdrop="static" keyboard={false} size='md' style={{width: '100vw'}} aria-labelledby="contained-modal-title-vcenter" centered>
              <Modal.Header className='text-dark' size='sm' closeButton>
                    <Modal.Title className='fs-6 text-bold'><Package /> افزودن محصول</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {addError ? <Error message="ثبت محصول بدلیل کد تکراری امکان پذیر نیست." title="خطای افزودن" /> : ""}
                  <Row className="mb-3">
                    <Col>
                      <label htmlFor="productCode" className="myform">کد محصول</label>
                      <input type="text" className="form-control form-control-lg" placeholder="مانند: 1658994785001" id="productCode" name="ProductCode" />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <label htmlFor="productTitle" className="myform">عنوان محصول</label>
                      <input type="text" className="form-control form-control-lg" placeholder="مانند: شلوار جین" id="productTitle" name="ProductTitle" />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <label htmlFor="productDescription" className="myform">توضیح محصول</label>
                      <textarea className="form-control form-control-lg" placeholder="توضیحات جزیی مانند نوع دوخت و الیاف و ..." id="productDescription" name="productDescription">

                      </textarea>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <button type="button" className="btn btn-lg d-flex w-100 justify-content-center align-items-center btn-success" onClick={addProductOp}>
                        ادامه
                      </button>
                    </Col>
                  </Row>
                </Modal.Body>
            </Modal>
          </>
        : ""
        }
      {/* Edit Product View */}
        {editProduct ? 
        <>
        <Modal show={editProduct} onHide={handleProductClose} backdrop="static" keyboard={false} size='xl' style={{width: '100vw'}} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className='text-dark' size='sm' closeButton>
                <Modal.Title className='fs-6 text-bold'><Package /> ویرایش محصول</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              {product && !productError && orderTypes && models && categories && sizes && colors && productImages && productVariation ?
              <>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={6}>
                    <div className="d-flex flex-row justify-content-start align-center flex-wrap">
                      {product.images && product.images.length !== 0  ? product.images.map (image => 
                      image.image ?
                      <div className="d-flex flex-row w-25 p-1">
                        <img src={`${BaseUrl}/../${image.image.path}/${image.image.filename}`} alt={image.image._id} className="d-block w-100 mx-1 img-thumbnail" />
                        <Button variant='white' size="sm" className="position-absolute m-1 border-0" onClick={() => deleteImage(image.image._id)}>
                          <XCircle color="#ee3030" />
                        </Button>
                      </div>
                      : ""
                      ):
                      <img src={require('../../src/default-product.jpg')} alt="No Products Found"  className="d-block rounded" />
                      }
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center p-3 rounded border-dashed mt-3">
                      <img src={require('../../src/select-image.png')} alt="Product" className="d-block w-25 m-auto"  onClick={() => document.getElementById('photo').click()}/>
                      <input type="file" id="photo" name="photo" accept=".jpg, .png" onChange={photoUpload} hidden />
                      <button className="btn btn-primary btn-sm" name="photoUpload" onClick={() => document.getElementById('photo').click()}>آپلود تصویر</button>
                    </div>
                    <div id="uploadError" className="d-none text-danger">خطائی پیش امده، مجددا تلاش کنید.</div>
                    <div id="progressParent" className="progress mt-2 d-none">
                      <div id="uploadProgress" className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </Col>
                  <Col>
                  <form onSubmit={updateProductDetails}>
                    <Row className="mb-2">
                      <Col>
                        <label className="myform">عنوان محصول: </label>
                        <input type="text" className="form-control form-control-sm" defaultValue={product.title} id="title" onChange={event => setProductName(event.target.value)} />
                      </Col>
                      <Col>
                        <label className="myform">کد محصول: </label>
                        <input type="text" className="form-control form-control-sm" defaultValue={product.code} id="code" onChange={event => setProductCode(event.target.value)} />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <label className="myform">نوع سفارش: </label>
                        <div className="p-1 border border-gray rounded">
                          {orderTypes.map(orderType => 
                            <div key={orderType._id} className="form-check">
                              <input onChange={event => setProductType(event.target.value)} className="form-check-input" type="radio" name="orderTypes" id={orderType._id} defaultValue={orderType._id} defaultChecked={product.type ? product.type._id === orderType._id : false} />
                              <label className="form-check-label" for={orderType._id}>{orderType.item}</label>
                            </div>
                          )}
                        </div>
                        <label className="myform">دسته‌بندی: </label>
                        <div className="p-1">
                          <select className="form-control form-control-sm" onChange={event => setProductCat(event.target.value)}>
                            {categories.map(category =>  
                                <option defaultValue={category._id} value={category._id} selected={product.category ? product.category._id === category._id : false}>{category.item}</option>
                            )}
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <label className="myform"> مدل محصول: </label>
                        <div className="p-1 border border-gray rounded">
                          {models.map(model => 
                            <div key={model._id} className="form-check">
                              <input onChange={event => setProductModel(event.target.id)} className="form-check-input" type="radio" name="models" id={model._id} defaultValue={model.item} defaultChecked={product.model._id === model._id} />
                              <label className="form-check-label" for={model._id}>{model.item}</label>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="myform">توضیحات: </label>
                        <textarea onChange={event => setProductDesc(event.target.value)} className="form-control form-contorl-sm" defaultValue={product.description}>
                        </textarea>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      <label className="myform">ویژگی‌های محصول: </label>
                      <div id="newVariation">

                      </div>
                      {product.variety.length !== 0 ?
                        product.variety.map (variation =>
                          !variation._id ? "" :
                        <>
                          <div id={variation._id} className="d-flex flex-row justify-content-around align-items-center p-2">
                            <div className="d-flex flex-column p-1">
                              {
                                variation.color && variation.color.length !== 0 ?
                                <>
                                <label htmlFor={variation.color._id}>رنگ: </label>
                                <input type="text" disable defaultValue={variation.color.item} id={variation.color._id} className="form-control form-control-sm" />
                                </>
                                :
                                <>
                                <label htmlFor='noColorChosen'>رنگ: </label>
                                <input type="text" readOnly defaultValue="رنگی انتخاب نشده!" id="noColorChosen" className="form-control form-control-sm" />
                                </>

                              }
                            </div>
                            <div className="d-flex flex-column p-1">
                              {
                                variation.size && variation.size.length !== 0 ?
                                <>
                                <label htmlFor={variation.size._id}>سایز: </label>
                                <input type="text" readOnly defaultValue={variation.size.item} id={variation.size._id} className="form-control form-control-sm" />
                                </>
                                :
                                <>
                                <label htmlFor='noSizeChosen'>سایز: </label>
                                <input type="text" readOnly defaultValue="سایزی انتخاب نشده!" id='noSizeChosen' className="form-control form-control-sm" />
                                </>
                              }
                            </div>
                            <div className="d-flex flex-column p-1">
                              {
                                variation.inStock ?
                                <>
                                <label htmlFor={variation.inStock + variation._id}>موجودی انبار: </label>
                                <input type="text" readOnly defaultValue={variation.inStock} id={variation.inStock + variation._id} className="form-control form-control-sm" />
                                </>
                                :
                                <>
                                <label htmlFor='noStockOptions'>موجودی انبار: </label>
                                <input type="text" disable defaultValue="موجودی انتخاب نشده!" id="noStockOptions" className="form-control form-control-sm" />
                                </>

                              }
                            </div>
                            <div className="d-flex flex-column p-1">
                              <label>عملیات: </label>
                              <button type="button" className="btn btn-danger btn-sm" onClick={() => deleteVariation(variation._id)}>
                                <XCircle color="#fff" />
                              </button>
                            </div>
                          </div>
                        </> 
                        ):
                        "این محصول دارای ویژگی نیست!"}
                        <div className="form bg-light rounded border border-grey my-2 p-2 d-flex flex-row justify-content-around align-items-center w-100">

                          <div className="d-flex flex-column p-1 w-100">
                            <label>انتخاب رنگ: </label>
                            <select className="form-control form-control-sm" id="colorVariation">
                              <option value="" selected="selected" disabled>- انتخاب رنگ -</option>
                              {
                                colors.map(color => 
                                    <option value={color._id}>{color.item}</option>
                                )
                              }
                            </select>
                          </div>

                          <div className="d-flex flex-column p-1 w-100">
                            <label>انتخاب سایز: </label>
                            <select className="form-control form-control-sm" id="sizeVariation">
                              <option value="" selected="selected" disabled>- انتخاب سایز -</option>
                              {
                                sizes.map(size => 
                                    <option value={size._id}>{size.item}</option>
                                )
                              }
                            </select>
                          </div>

                          <div className="d-flex flex-column p-1 w-100">
                            <label>موجودی انبار: </label>
                            <input type="text" name="stockVariation" id="stockVariation" className="form-control form-control-sm" />
                          </div>

                          <div className="d-flex flex-column p-1">
                            <label>عملیات: </label>
                            <button type="button" className="btn btn-success btn-sm" onClick={addVariation}>
                                <Plus color="#fff" />
                              </button>
                          </div>

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button variant='primary' onClick={updateProductDetails}>بروزرسانی</Button>
                      </Col>
                    </Row>
                  </form>
                  </Col>
                </Row>
              </>
              :
              !product && productError ?
              <div>error</div>
              :
              <>
              {/* Product Edit Loading */}
                <Row>
                  <Col xs={12} sm={12} md={12} lg={6} className="">
                    <Skeleton height={400} width={400} />
                  </Col>
                  <Col>
                    <h2 className="d-flex align-items-center justify-content-between mx-1 text-bold text-dark fs-6 mb-3"><Skeleton width={100} /><span className=""><Skeleton width={100} /></span></h2>
                    <div className="d-flex flex-row justify-content-between align-items-baseline">
                        <Skeleton width={100} /> <Skeleton width={100} />
                        <Skeleton width={100} /> <Skeleton width={100} />
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-baseline">
                      
                      <Skeleton width={100} /> <Skeleton width={100} />

                      <Skeleton width={100} />  <Skeleton width={100} />
                    </div>
                    <div className="mx-1 bg-light p-2 rounded">
                      <Skeleton width={100} />
                      <Skeleton count={3} />
                    </div>
                    <Table responsive bordered size="sm" className="mt-3 rounded">
                      <thead className="bg-grey">
                        <tr>
                          <th scope="col"><Skeleton width={100} /></th>
                          <th scope="col"><Skeleton width={100} /></th>
                          <th scope="col"><Skeleton width={100} /></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><Skeleton width={100} /></td>
                          <td><Skeleton width={100} /></td>
                          <td><Skeleton width={100} /></td>
                        </tr>
                        <tr>
                          <td><Skeleton width={100} /></td>
                          <td><Skeleton width={100} /></td>
                          <td><Skeleton width={100} /></td>
                        </tr>
                        <tr>
                          <td><Skeleton width={100} /></td>
                          <td><Skeleton width={100} /></td>
                          <td><Skeleton width={100} /></td>
                        </tr>
                        <tr>
                          <td><Skeleton width={100} /></td>
                          <td><Skeleton width={100} /></td>
                          <td><Skeleton width={100} /></td>
                        </tr>
                        <tr>
                          <td><Skeleton width={100} /></td>
                          <td><Skeleton width={100} /></td>
                          <td><Skeleton width={100} /></td>
                        </tr>
                      </tbody>
                    </Table>
                    
                  </Col>
                </Row>
              </>
              }
            </Modal.Body>
        </Modal>
        </>
        :
        ""
        }
        {/* Single Product View */}
        {showSingle ? 
        <>
        <Modal show={showSingle} onHide={handleProductClose} backdrop="static" keyboard={false} size='xl' style={{width: '100vw'}} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className='text-dark' size='sm' closeButton>
                <Modal.Title className='fs-6 text-bold'><Package /> اطلاعات محصول</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              {product && !productError ?
              <>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={6} className="">
                  <Carousel variant="light" indicators={false} controls={true} style={{overflow: "hidden", margin: "auto", display: "flex", alignItems: "center"}}>

                    {product.images && product.images.length !== 0  ? product.images.map (image => 
                        image.image ?
                          <Carousel.Item key={image._id}>
                            <img src={`${BaseUrl}/../${image.image.path}/${image.image.filename}`} alt={image.image._id} className="d-block w-100 my-auto mx-auto h-100" />
                          </Carousel.Item>
                          : ""
                    ):
                      <Carousel variant="dark" indicators={false} controls={false} style={{width: "120px"}} className="m-2"  interval={null}>
                        <img src={require('../../src/default-product.jpg')} alt="No Products Found"  className="d-block rounded" width={120} height={120} />
                      </Carousel>
                    }
                  </Carousel>
                  </Col>
                  <Col>
                    <h2 className="d-flex align-items-center justify-content-between mx-1 text-bold text-dark fs-6 mb-3">{product.title}<span className="mx-1 fs-6 p-1 rounded text-light bg-dark"><Folder size={16} /> {product.category.item}</span></h2>
                    <div className="d-flex flex-row justify-content-between align-items-baseline">
                      <p className="w-50 mx-1 border border-grey rounded text-center p-1">
                        <p className="text-bold text-center">کد</p> {product.code}
                      </p>
                      <p className="w-50 mx-1 border border-grey rounded text-center p-1">
                        <p className="text-bold text-center">مدل</p> {product.model.item}
                      </p>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-baseline">
                      <p className="w-50 mx-1 border border-grey rounded text-center p-1">
                      <p className="text-bold text-center">نوع</p> {product.type ? product.type.item : "نامشخص"}
                      </p>
                      <p className="w-50 mx-1 border border-grey rounded text-center p-1">
                      <p className="text-bold text-center">شناسه سیستمی</p> {product._id}
                      </p>
                    </div>
                    <div className="mx-1 bg-light p-2 rounded">
                      <p className="text-bold">توضیحات:</p>
                      <p>{product.description}</p>
                    </div>
                    <Table responsive bordered centered size="sm" className="mt-3 rounded">
                      <thead className="bg-grey">
                        <tr>
                          <th scope="col">رنگ</th>
                          <th scope="col">سایز</th>
                          <th scope="col">موجودی انبار</th>
                        </tr>
                      </thead>
                      <tbody>
                      {product.variety.map (variation => 
                        <tr key={variation._id} id={variation._id}>
                          <td>{variation.color ? variation.color.item : "نامشخص"}</td>
                          <td>{variation.size ? variation.size.item : "نامشخص"}</td>
                          <td>{variation.inStock}</td>
                        </tr>
                      )}
                      </tbody>
                    </Table>
                    
                  </Col>
                </Row>
              </>
              :
              !product && productError ?
              <>
                <Error message="در هنگام دریافت اطلاعات محصول، خطایی پیش امد. لطفا مجددا تلاش کنید و یا با ادمین سیستم تماس بگیرید." title="دریافت محصول با خطا مواجه شد!" />
              </>
              :
              <>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={6} className="">
                    <Skeleton height={400} width={400} />
                  </Col>
                  <Col>
                    <h2 className="d-flex align-items-center justify-content-between mx-1 text-bold text-dark fs-6 mb-3"><Skeleton width={100} /><span className=""><Skeleton width={100} /></span></h2>
                    <div className="d-flex flex-row justify-content-between align-items-baseline">
                      <p className="w-50 mx-1 border border-grey rounded text-center p-1">
                        <p className="text-bold text-center"><Skeleton width={100} /></p> <Skeleton width={100} />
                      </p>
                      <p className="w-50 mx-1 border border-grey rounded text-center p-1">
                        <p className="text-bold text-center"><Skeleton width={100} /></p> <Skeleton width={100} />
                      </p>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-baseline">
                      <p className="w-50 mx-1 border border-grey rounded text-center p-1">
                      <p className="text-bold text-center"><Skeleton width={100} /></p> <Skeleton width={100} />
                      </p>
                      <p className="w-50 mx-1 border border-grey rounded text-center p-1">
                      <p className="text-bold text-center"><Skeleton width={100} /></p> <Skeleton width={100} />
                      </p>
                    </div>
                    <div className="mx-1 bg-light p-2 rounded">
                      <p className="text-bold"><Skeleton width={100} />:</p>
                      <p><Skeleton count={3} /></p>
                    </div>
                    <LoadingTable />
                    
                  </Col>
                </Row>
              </>
              }
            </Modal.Body>
        </Modal>
        </>
        :
        ""
        }
        {/* Single Product Delete */}
        {show ? 
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='text-danger' size='sm' closeButton>
                <Modal.Title className='fs-6 text-bold'><AlertTriangle /> تایید حذف</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-bold fs-7">
              آیا از حذف این آیتم اطمینان دارید؟
              {deleteErr ? <div className="alert alert-danger my-2"><AlertTriangle /> خطایی پیش آمد.</div> : ''}
            </Modal.Body>
            <Modal.Footer className='d-flex flex-row justify-content-between'>
                <Button variant="outline" size="sm" onClick={handleClose}>
                  برگشت
                </Button>
                {!loading ?
                <Button variant="danger" size="sm" onClick={deleteItem}>
                  <Trash2 size={16} /> حذف آیتم
                </Button>
                :
                <Button variant="danger" size="sm" disabled>
                  شکیبا باشید...
                </Button>
                }
            </Modal.Footer>
        </Modal>
        : ''}
        <Breadcrumb title={props.title} />
        <Col className='d-flex flex-column justify-content-start border border-1 border-light p-3 my-2 bg-white rounded shadow-sm'>
          {
            data && !error ? 
            data.length !== 0 ?
            <>
              <div className="d-flex flex-row justify-content-between align-items-center border-bottom mb-4 px-1 py-2">
                <h2 className="text-bold accent-color fs-5 m-0 p-0"><Package size={18} /> {props.title}</h2>
                <div name="buttons">
                  <Button variant="outline-warning" size="sm" className="text-bold" onClick={addNewForm}><Plus size={16} /> افزودن</Button>
                </div>
              </div>
              <Table responsive hover size="sm">
                <thead className="align-middle">
                  <tr>
                    <th scope="col" className="text-bold fw-normal fs-7 text-nowrap"><Hash size={14} /> کد محصول</th>
                    <th scope="col" className="text-bold fw-normal fs-7"><Image size={14} /> تصویر</th>
                    <th scope="col" className="text-bold fw-normal fs-7 text-nowrap"><Tag size={14} /> عنوان</th>
                    <th scope="col" className="text-bold fw-normal fs-7 text-nowrap"><Filter size={14} /> نوع سفارش</th>
                    <th scope="col" className="text-bold fw-normal fs-7 text-nowrap"><Folder size={14} /> مجموعه</th>
                    <th scope="col" className="text-bold fw-normal fs-7 text-nowrap"><Zap size={14} /> عملیات</th>
                  </tr>
                </thead>
                <tbody className="align-middle">
                  {data.map(data => 
                    <tr key={data._id}>
                      <td className="text-nowrap">{data.code}</td>
                      <td>
                        {data.images && data.images.length !== 0  ?
                        <Carousel variant="dark" indicators={false} controls={false} style={{width: "120px"}} className="m-2"  interval={null}>
                          {data.images.map (image => 
                            image.image ?
                              <Carousel.Item key={image._id}>
                                <img src={`${BaseUrl}/../${image.image.path}/${image.image.filename}`} alt={image.image._id} className="d-block rounded" width={120} height={120} />
                              </Carousel.Item>
                            : ""
                          )}
                        </Carousel>
                        :
                        <Carousel variant="dark" indicators={false} controls={false} style={{width: "120px"}} className="m-2"  interval={null}>
                        <img src={require('../../src/default-product.jpg')} alt="No Products Found"  className="d-block rounded" width={120} height={120} />
                        </Carousel>
                        }
                      </td>
                      <td className="text-nowrap">
                        <span className="text-bold">{data.title}</span> 
                        <span className="border-warning border border-1 text-warning rounded mx-1 px-1"><small>{data.model && data.model.length !== 0  ? data.model.item : "-"}</small></span>
                      </td>
                      <td className='text-nowrap'>{data.type && data.type.length !== 0  ? data.type.item : ""}</td>
                      <td className='text-nowrap'>{data.category && data.category.length !== 0  ? data.category.item : ""}</td>
                      <td className='text-nowrap'>
                        <Eye color='#323232' size={18} className='mx-2' onClick={() => handleProductShow(data._id)} />
                        <Edit color='#7941d4' size={18} className='mx-2' onClick={() => handleProductEdit(data._id)} />
                        <Trash2 color='#ee3030' size={18} className='mx-2' onClick={() => handleShow(data._id)} />
                      </td>
                    </tr>
                    )
                  }
                </tbody>
              </Table>
            </>
            :
            <>
              <div className="d-flex flex-row justify-content-between align-items-center border-bottom mb-4 px-1 py-2">
                <h2 className="text-bold accent-color fs-5 m-0 p-0"><Package size={18} /> {props.title}</h2>
                <div name="buttons">
                  <Button variant="outline-warning" size="sm" className="text-bold" onClick={addNewForm}><Plus size={16} /> افزودن</Button>
                </div>
              </div>
              <NoItemFound />
            </>
            :
            !data && error ?
            <>
            <Error title="خطا در دریافت اطلاعات" message="دریافت اطلاعات از سرور با خطا مواجه شد! لطفا مجددا تلاش کنید و یا با مدیر پنل تماس بگیرید." />
            </>
            :
            <>
              <div className="d-flex flex-row justify-content-between align-items-center border-bottom mb-4 px-1 py-2">
                <h2 className="text-bold accent-color fs-5 m-0 p-0"><Package size={18} /> {props.title}</h2>
                <div name="buttons">
                  <Button variant="outline-warning" size="sm" className="text-bold" onClick={addNewForm}><Plus size={16} /> افزودن</Button>
                </div>
              </div>
              <LoadingTable />
            </>
          }
        </Col>
    </div>
  )
}

export default Products