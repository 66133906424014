import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import { Menu, LogOut, Grid, Package, ShoppingCart, Users, Settings, ArrowLeft, ChevronDown, FileText } from 'react-feather';
import {Col} from 'react-bootstrap';

const NavMenu = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <Col className="d-flex justify-content-between align-items-center border border-1 border-light p-3 my-2 bg-white rounded shadow-sm">
      <Button variant="outline-white border-0 mt-0 pt-0" onClick={handleShow}>
        <Menu onClick={handleShow} color="#dba52f" size={22} />
      </Button>
      <Button variant="outline-white border-0 mt-0 pt-0" onClick={handleShow}>
        <LogOut color="red" size={22} />
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{props.title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column">
          <Link className='fs-6 text-decoration-none text-dark my-2' to="/" onClick={handleClose}><Grid className="me-3" color="#dba52f" size={18} /> داشبورد</Link>
          <Link className='fs-6 text-decoration-none text-dark my-2' to="/Products" onClick={handleClose}><Package className="me-3" color="#dba52f" size={18} /> محصولات</Link>
          <Link className='fs-6 text-decoration-none text-dark my-2' to="/Products" onClick={handleClose}><ShoppingCart className="me-3" color="#dba52f" size={18} /> سفارشات</Link>
          <Link className='fs-6 text-decoration-none text-dark my-2' to="/Users" onClick={handleClose}><Users className="me-3" color="#dba52f" size={18} /> نمایندگان</Link>
          <Link className='fs-6 text-decoration-none text-dark my-2' to="/Products" onClick={handleClose}><FileText className="me-3" color="#dba52f" size={18} /> گزارشات</Link>
          <div className='fs-6 text-decoration-none text-dark my-2'><Settings className="me-3" color="#dba52f" size={18} /> پارامترها <ChevronDown className="float-end mt-1" color="#888" size={16} /></div>
          <Link className='fs-7 text-decoration-none text-dark my-1 mx-2' to="/Categories" onClick={handleClose}><ArrowLeft className="me-2" color="#dba52f" size={16} /> مجموعه‌ها</Link>
          <Link className='fs-7 text-decoration-none text-dark my-1 mx-2' to="/Colors" onClick={handleClose}><ArrowLeft className="me-2" color="#dba52f" size={16} /> رنگ‌بندی</Link>
          <Link className='fs-7 text-decoration-none text-dark my-1 mx-2' to="/Sizes" onClick={handleClose}><ArrowLeft className="me-2" color="#dba52f" size={16} /> سایزبندی</Link>
          <Link className='fs-7 text-decoration-none text-dark my-1 mx-2' to="/OrderTypes" onClick={handleClose}><ArrowLeft className="me-2" color="#dba52f" size={16} /> انواع سفارش</Link>
        </Offcanvas.Body>
      </Offcanvas>
    </Col>
  )
}

export default NavMenu