import Skeleton from "react-loading-skeleton"
import { Table } from "react-bootstrap"

const LoadingTable = () => {
  return (
    <Table>
        <thead>
            <tr>
                <th><Skeleton /></th>
                <th><Skeleton /></th>
                <th><Skeleton /></th>
                <th><Skeleton /></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
            <tr>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
                <td><Skeleton /></td>
            </tr>
        </tbody>
    </Table>
  )
}

export default LoadingTable