//import React, {useState, useEffect} from 'react';
//import axios from 'axios';
import {  BrowserRouter,  Routes,  Route} from "react-router-dom";
import {Container, Row, Col} from 'react-bootstrap';
import './App.css';
import NavMenu from './Components/NavMenu';
import Dashboard from './Pages/Dashboard';
import Products from './Pages/Products';
import Categories from "./Pages/Categories";
import Colors from "./Pages/Colors";
import Sizes from "./Pages/Sizes";
import OrderTypes from "./Pages/OrderTypes";
import Users from "./Pages/Users";


function App() {
  return (
    <div className="App">
      <Container>
        <Row>
          <BrowserRouter>
            <Col xs={12}>
              <NavMenu title='مدیریت نمایندگان یزدباف' />
            </Col>
            <Col xs={12}>
              <Routes>
                <Route index element={<Dashboard title='داشبورد' />} /> {/* TODO: Add Dashboard Title To Config JSON */}
                <Route path="/" element={<Dashboard title='داشبورد' />}></Route>
                <Route path="/Products" element={<Products title='محصولات' />}></Route>
                <Route path="/Categories" element={<Categories title='مجموعه‌ها' />}></Route>
                <Route path="/Colors" element={<Colors title='رنگ‌‌بندی' />}></Route>
                <Route path="/Sizes" element={<Sizes title='سایزبندی' />}></Route>
                <Route path="/OrderTypes" element={<OrderTypes title='انواع سفارش' />}></Route>
                <Route path="/Users" element={<Users title='نمایندگان' />}></Route>
              </Routes>
            </Col>
          </BrowserRouter>
        </Row>
      </Container>
    </div>
  );
}

export default App;
