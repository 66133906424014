import {Col, Alert, Button} from 'react-bootstrap';
import { AlertTriangle } from 'react-feather';
import { useNavigate } from "react-router-dom";

const Error = (props) => {
    const navigate = useNavigate();
    const backButton = async () => {
        navigate(-1);
    };
  return (
    <Col>
    <Alert variant='danger' className='m-0'>
        <Alert.Heading className="text-bold">
            <AlertTriangle /> {props.title}
        </Alert.Heading>
        <div className='d-flex justify-content-start'>{props.message}</div>
        {props.button ?
        <div className='d-flex justify-content-end'>
            <Button variant='outline-danger' onClick={backButton}>
                برگشت
            </Button>
        </div>
        : ""}
    </Alert>
    </Col>
  )
}

export default Error