import Breadcrumb from "../Components/Breadcrumb"
import { Grid, Package, Users, ShoppingBag } from 'react-feather';
import axios from "axios"
import Skeleton from 'react-loading-skeleton'
import BaseUrl from '../Config';
import {useState, useEffect} from "react"
import Error from "../Components/Error"
import {Col, Button, Modal, Table, Row, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Dashboard = (props) => {
  const [products, setProducts] = useState(null)
  const [error, setError] = useState(false)
  const [loading, isLoading] = useState(true)
  const getProducts = async (id) => {
    axios.get(`${BaseUrl}/products`)
    .then(response => {
      setProducts(response.data.response)
      setError(false);
      isLoading(false);
    })
    .catch(err => {
      setProducts(null)
      setError(true)
      isLoading(false);
    });
  };
  const [users, setUsers] = useState(null)
  const [error2, setError2] = useState(false)
  const [loading2, isLoading2] = useState(true)
  const getCategories = async (id) => {
    axios.get(`${BaseUrl}/users`)
    .then(response => {
      setUsers(response.data.response)
      setError2(false);
      isLoading2(false);
    })
    .catch(err => {
      setUsers(null)
      setError2(true)
      isLoading2(false);
    });
  };
  useEffect(() => {
    getProducts();
    getCategories();
  }, [])
  return (
    <div>
        <Breadcrumb title={props.title} />
        <Col className='d-flex flex-column justify-content-start border border-1 border-light p-3 my-2 bg-white rounded shadow-sm'>
            <Row>
              <div className="d-flex flex-row justify-content-between align-items-center border-bottom mb-4 px-1 py-2">
                  <h2 className="text-bold accent-color fs-5 m-0 p-0"><Grid size={18} /> {props.title}</h2>
              </div>
            </Row>
            <Row className="">
              <Col className="">
                <Link to="/products" className="d-flex flex-column justify-content-center align-items-center">
                  <Package size={42} className="text-secondary" /> 
                  {products && !error ?
                    <>
                    <div className="text-danger fw-bold fs-2 text-black">
                      {products.length}
                    </div>
                    </>
                  : !products && error ?
                  <>
                  <div className="text-danger fw-bold fs-4">
                    خطا!
                  </div>
                  </>
                  : loading ?
                  <>
                    <Skeleton width={40} height={40} />
                  </>
                  : ""
                }
                  <div className="d-flex flex-row justify-content-start align-items-center fs-4 fw-bold text-secondary">
                    محصول
                  </div>
                  <div className="d-flex flex-row justify-content-end align-items-center text-primary">
                    اطلاعات بیشتر
                  </div>
                </Link>
              </Col>
              <Col className="">
                <Link to="/users" className="d-flex flex-column justify-content-center align-items-center">
                  <Users size={42} className="text-secondary" /> 
                  {users && !error2 ?
                    <>
                    <div className="text-danger fw-bold fs-2 text-black">
                      {users.length}
                    </div>
                    </>
                  : !users && error2 ?
                  <>
                  <div className="text-danger fw-bold fs-4">
                    خطا!
                  </div>
                  </>
                  : loading2 ?
                  <>
                    <Skeleton width={40} height={40} />
                  </>
                  : ""
                }
                  <div className="d-flex flex-row justify-content-start align-items-center fs-4 fw-bold text-secondary">
                    نماینده
                  </div>
                  <div className="d-flex flex-row justify-content-end align-items-center text-primary">
                    اطلاعات بیشتر
                  </div>
                </Link>
              </Col>
              <Col className="">
                <Link to="/products" className="d-flex flex-column justify-content-center align-items-center">
                  <ShoppingBag size={42} className="text-secondary" /> 
                  {products && !error ?
                    <>
                    <div className="text-danger fw-bold fs-2 text-black">
                      {products.length}
                    </div>
                    </>
                  : !products && error ?
                  <>
                  <div className="text-danger fw-bold fs-4">
                    خطا!
                  </div>
                  </>
                  : loading ?
                  <>
                    <Skeleton width={40} height={40} />
                  </>
                  : ""
                }
                  <div className="d-flex flex-row justify-content-start align-items-center fs-4 fw-bold text-secondary">
                    سفارش
                  </div>
                  <div className="d-flex flex-row justify-content-end align-items-center text-primary">
                    اطلاعات بیشتر
                  </div>
                </Link>
              </Col>
            </Row>
        </Col>
        <Col>
        </Col>
    </div>
  )
}

export default Dashboard