import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Breadcrumb from "../Components/Breadcrumb"
import Error from "../Components/Error"
import {Col, Button, Modal, Spinner } from "react-bootstrap"
import { AlertTriangle, Plus, ArrowLeft } from 'react-feather'
import NoItemFound from '../Components/NoItemFound'
import BaseUrl from '../Config'
import CategoriesTable from '../Components/CategoriesTable'
import LoadingTable from '../Components/LoadingTable'

const Categories = (props) => {
    const [data, setData] = useState(null);
    const [dataErr, setDataErr] = useState(null);
    const [deleteId, setDeleteId] = useState(null);

    const [deleteModal, setDeleteModal] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    
    const [addModal, setAddModal] = useState(null);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [addError, setAddError] = useState(false);



    const showDeleteModal = () => {
        setDeleteModal(true);
        setLoadingDelete(false);
    };

    const closeDeleteModal = () => {
        setDeleteModal(null);
        setDeleteId(null);
        setDeleteError(false);
        setLoadingDelete(false);
    };

    const confirmDeleteItem = async (id) => {
        setLoadingDelete(true);
        setDeleteError(false);
        await axios.delete(`${BaseUrl}/categories/${id}`)
        .then (response => {
            setDeleteId(null);
            setDeleteModal(null);
            setLoadingDelete(false);
        })
        .catch (err => {
            setLoadingDelete(false);
            setDeleteError(true);
        });
    };
    
    const confirmAddItem = async () => {
        const item = document.getElementById('categoryName');
        const code = document.getElementById('categoryCode');
        const parent = document.getElementById('parentName');
        
        var newData = '';
        console.log('clicked');

        if (!item.value) {
            item.classList.add('border-danger');
        } else {
            item.classList.remove('border-danger');
            item.classList.add('border-success');
        }

        if (!code.value) {
            code.classList.add('border-danger');
        } else {
            code.classList.remove('border-danger');
            code.classList.add('border-success');
        }

        if (!parent.value) {
            parent.classList.add('border-danger');
        } else {
            parent.classList.remove('border-danger');
            parent.classList.add('border-success');
        }
        if (item.value && parent.value === "none" && code.value) {
            setLoadingAdd(true);
            // eslint-disable-next-line
            var newData = JSON.stringify({
                item: item.value,
                code: code.value
            });
            const headers = {
                headers: {
                'Content-Type': 'application/json'
                }
            };
            const url = `${BaseUrl}/categories/new`;
            await axios.post(url, newData, headers)
            .then (response => {
                setAddModal(null);
                setLoadingAdd(false);
            })
            .catch (err => {
                setLoadingAdd(false);
                setAddError(true);
            });
        } 
        if (item.value && parent.value && parent.value !== "none" && code.value) {
            setLoadingAdd(true);
            // eslint-disable-next-line
            var newData = JSON.stringify({
                item: item.value,
                parent: parent.value,
                code: code.value
            });
            const headers = {
                headers: {
                'Content-Type': 'application/json'
                }
            };
            const url = `${BaseUrl}/categories/new`;
            await axios.post(url, newData, headers)
            .then (response => {
                setAddModal(null);
                setLoadingAdd(false);
            })
            .catch (err => {
                setLoadingAdd(false);
                setAddError(true);
            });
        }
    };

    const showAddModal = () => {
        setAddModal(true);
        setLoadingAdd(false);
    };

    const closeAddModal = () => {
        setAddModal(null);
        setAddError(false);
        setLoadingAdd(false);
    };

    const addNewForm = () => {
        setAddModal(true);
    };
    const getItems = () => {
        axios.get(`${BaseUrl}/categories`)
        .then (response => {
            setData(response.data.response);
            setDataErr(false);
        })
        .catch (err => {
            setDataErr(true);
            setData(null);
        });
    };
    const deleteItem = (param) => {
        setDeleteId(param);
        setDeleteModal(true);
    };
    useEffect(() => {
        getItems();
    }, [data])
  return (
    <>
        <Breadcrumb title={props.title} />
        <Col className='d-flex flex-column justify-content-start border border-1 border-light p-3 my-2 bg-white rounded shadow-sm'>
            <div className="d-flex flex-row justify-content-between align-items-center border-bottom mb-4 px-1 py-2">
                <h2 className="text-bold accent-color fs-5 m-0 p-0"><ArrowLeft size={18} /> {props.title}</h2>
                <div name="buttons">
                    <Button variant="outline-warning" size="sm" className="text-bold" onClick={addNewForm}><Plus size={16} /> افزودن</Button>
                </div>
            </div>
            {
                data && !dataErr ?
                    data.length !== 0 ?
                    <CategoriesTable theads={['کد','نام مجموعه', 'مجموعه والد', 'عملیات']} data={data} deleteItem={deleteItem} />
                        :
                    <NoItemFound />
                :
                !data && !dataErr ?
                    <LoadingTable />
                :
                <Error title="خطا" message="خطایی در هنگام دریافت اطلاعات بوجود آمد! لطفا مجددا تلاش کنید..." />
            }
        </Col>
        {deleteModal ?
        <Modal show={showDeleteModal} onHide={closeDeleteModal} backdrop="static" keyboard={false} size='md' style={{width: '100vw'}} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='bg-danger text-white' size='sm' closeButton>
                <Modal.Title className='fs-6 text-bold'><AlertTriangle /> حذف آیتم</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    deleteError ? 
                    <>
                    <Error title="خطا" message="حذف این آیتم با خطا مواجه شد! لطفا مجددا تلاش کنید و در صورت تکرار خطا با ادمین تماس بگیرید." />
                    </>
                    :
                    ""
                }
                <div className='pt-3 pb-3'>آیا از حذف این آیتم اطمینان دارید؟</div>
            </Modal.Body>
            <Modal.Footer className='d-flex flex-row justify-content-between align-items-center'>
                    {loadingDelete ?
                    <>
                    <div className='mx-auto'>
                        <Spinner animation="grow"  variant='danger' role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                    </>
                    :
                    <>
                    <button type="button" className="btn btn-white btn-sm" onClick={closeDeleteModal}>
                        برگشت
                    </button>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => confirmDeleteItem(deleteId)}>
                    حذف
                    </button>
                    </>
                    }
            </Modal.Footer>
        </Modal>
        : ""}
        {addModal ?
        <Modal show={showAddModal} onHide={closeAddModal} backdrop="static" keyboard={false} size='md' style={{width: '100vw'}} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='text-dark' size='sm' closeButton>
                <Modal.Title className='fs-6 text-bold'><Plus /> افزودن آیتم</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    addError ? 
                    <>
                    <Error title="خطا" message="افزودن آیتم جدید با خطا مواجه شد! لطفا مجددا تلاش کنید و درصورت تکرار این خطا با ادمین تماس بگیرید." />
                    </>
                    :
                    ""
                }
                <div className='pt-3 pb-3'>
                    <input type="text" id="categoryName" className="form-control form-control-lg mb-3" placeholder="نام مجموعه" />
                    <input type="text" id="categoryCode" className="form-control form-control-lg mb-3" placeholder="کد مجموعه" />
                    <select className="form-control form-control-lg" id="parentName">
                        <option value="none">بدون والد</option>
                        {data.map (category =>
                        <option value={category._id}>{category.code} - {category.item}</option>
                        )}
                    </select>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex flex-row justify-content-between align-items-center'>
                    {loadingAdd ?
                    <>
                    <div className='mx-auto'>
                        <Spinner animation="grow"  variant='primary' role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                    </>
                    :
                    <>
                    <button type="button" className="btn btn-lg d-flex w-100 justify-content-center align-items-center btn-primary" onClick={() => confirmAddItem()}>
                    افزودن
                    </button>
                    </>
                    }
            </Modal.Footer>
        </Modal>
        : ""}
    </>
  )
}

export default Categories