import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Breadcrumb from "../Components/Breadcrumb"
import Error from "../Components/Error"
import {Col, Button, Modal, Spinner, Row } from "react-bootstrap"
import { AlertTriangle, Plus, ArrowLeft, XCircle } from 'react-feather'
import NoItemFound from '../Components/NoItemFound'
import BaseUrl from '../Config'
import LoadingTable from '../Components/LoadingTable'
import UsersTable from '../Components/UsersTable'

const Users = (props) => {
    const [data, setData] = useState(null);
    const [dataErr, setDataErr] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    
    const [deleteModal, setDeleteModal] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    
    const [addModal, setAddModal] = useState(null);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [addError, setAddError] = useState(false);
    
    const [editId, setEditId] = useState(null);
    const [editModal, setEditModal] = useState(null);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [editError, setEditError] = useState(false);
    const [theItem, setTheItem] = useState(null);

    const editItem = async (param) => {
        setEditId(param);
        setEditModal(true);
        await axios(`${BaseUrl}/profile/${param}`)
        .then (response => {
            setTheItem(response.data.response);
            setLoadingEdit(false);
            setEditError(false);
        })
        .catch (err => {
            setEditError(true);
            setLoadingEdit(false);
            setTheItem(null);
        });
    };

    const showEditModal = () => {
        setTheItem(null);
        setEditModal(true);
        setLoadingEdit(false);
    };

    const closeEditModal = () => {
        setEditModal(null);
        setEditId(null);
        setEditError(false);
        setLoadingEdit(false);
        setTheItem(null);
    };
    
    const showDeleteModal = () => {
        setDeleteModal(true);
        setLoadingDelete(false);
    };

    const closeDeleteModal = () => {
        setDeleteModal(null);
        setDeleteId(null);
        setDeleteError(false);
        setLoadingDelete(false);
    };

    const confirmDeleteItem = async (id) => {
        setLoadingDelete(true);
        setDeleteError(false);
        await axios.delete(`${BaseUrl}/deleteuser/${id}`)
        .then (response => {
            setDeleteId(null);
            setDeleteModal(null);
            setLoadingDelete(false);
        })
        .catch (err => {
            setLoadingDelete(false);
            setDeleteError(true);
        });
    };
    
    const confirmAddItem = async (id) => {
        const title = document.getElementById('title');
        const firstName = document.getElementById('firstName');
        const lastName = document.getElementById('lastName');
        const mobile = document.getElementById('mobile');
        const password = document.getElementById('password');
        const password_repeat = document.getElementById('password_repeat');
        const address = document.getElementById('address');

        var newData = '';

        if (!title.value) {
            title.classList.add('border-danger');
        } else {
            title.classList.remove('border-danger');
            title.classList.add('border-success');
        }

        if (!firstName.value) {
            firstName.classList.add('border-danger');
        } else {
            firstName.classList.remove('border-danger');
            firstName.classList.add('border-success');
        }

        if (!lastName.value) {
            lastName.classList.add('border-danger');
        } else {
            lastName.classList.remove('border-danger');
            lastName.classList.add('border-success');
        }

        if (!mobile.value) {
            mobile.classList.add('border-danger');
        } else {
            mobile.classList.remove('border-danger');
            mobile.classList.add('border-success');
        }

        if (!password.value) {
            password.classList.add('border-danger');
        } else {
            password.classList.remove('border-danger');
            password.classList.add('border-success');
        }

        if (!password_repeat.value) {
            password_repeat.classList.add('border-danger');
        } else {
            password_repeat.classList.remove('border-danger');
            password_repeat.classList.add('border-success');
        }

        if (!address.value) {
            address.classList.add('border-danger');
        } else {
            address.classList.remove('border-danger');
            address.classList.add('border-success');
        }


        if (address.value && password_repeat.value && password.value && mobile.value && lastName.value && firstName.value && title.value) {
            if (password.value !== password_repeat.value) {
                password.classList.add('border-danger');
                password_repeat.classList.add('border-danger');
            } else {
                password.classList.remove('border-danger');
                password.classList.add('border-success');
                password_repeat.classList.remove('border-danger');
                password_repeat.classList.add('border-success');
                setLoadingAdd(true);
                // eslint-disable-next-line
                var newData = JSON.stringify({
                    title: title.value,
                    firstName: firstName.value,
                    lastName: lastName.value,
                    mobile: mobile.value,
                    password: password.value,
                    address: address.value,
                });
                const headers = {
                    headers: {
                    'Content-Type': 'application/json'
                }
            };
                const url = `${BaseUrl}/register`;
                await axios.post(url, newData, headers)
                .then (response => {
                    setAddModal(null);
                    setLoadingAdd(false);
                    setAddError(false);
                })
                .catch (err => {
                    setLoadingAdd(false);
                    setAddError(true);
                });
            }
        } 
    };

    const showAddModal = () => {
        setAddModal(true);
        setLoadingAdd(false);
    };

    const closeAddModal = () => {
        setAddModal(null);
        setAddError(false);
        setLoadingAdd(false);
    };

    const addNewForm = () => {
        setAddModal(true);
    };
    const getItems = () => {
        axios.get(`${BaseUrl}/users`)
        .then (response => {
            setData(response.data.response);
            setDataErr(false);
        })
        .catch (err => {
            setDataErr(true);
            setData(null);
        });
    };
    const deleteItem = (param) => {
        setDeleteId(param);
        setDeleteModal(true);
    };

    const photoUpload = async () => {
        const x = document.getElementById('avatar');
        console.log(x.files[0].name);
        console.log(x.files[0].size);
        document.getElementById('progressParent').classList.remove('d-none');
        document.getElementById('uploadProgress').classList.add('w-25', 'progress-bar-striped', 'progress-bar-animated');
        document.getElementById('uploadProgress').classList.remove('w-100', 'bg-success');
        document.getElementById('uploadError').classList.add('d-none');
        var bodyFormData = new FormData();
        bodyFormData.append('avatar', x.files[0]);
        await axios({
          method: "post",
          url: `${BaseUrl}/avatars/new`,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(async (response) => {
          //handle success
          console.log(response.data.response._id);
          document.getElementById('uploadProgress').classList.remove('w-25');
          document.getElementById('uploadProgress').classList.add('w-75');

  
          await axios.put(`${BaseUrl}/updateprofile/${editId}`, {avatar: response.data.response._id})
          .then (results => {
            document.getElementById('uploadProgress').classList.remove('w-75');
            document.getElementById('uploadProgress').classList.add('w-100');
            document.getElementById('uploadProgress').classList.add('bg-success');
            document.getElementById('uploadProgress').classList.remove('progress-bar-striped', 'progress-bar-animated');
            setTimeout(() => {
              document.getElementById('progressParent').classList.add('d-none');
            }, 2000);
            //handleProductEdit(productId);
          })
          .catch(err => {
            document.getElementById('progressParent').classList.add('d-none');
            document.getElementById('uploadError').classList.remove('d-none');
          });
        })
        .catch((response) => {
          document.getElementById('progressParent').classList.add('d-none');
            document.getElementById('uploadError').classList.remove('d-none');
        });
    };

    const deleteImage = async (id) => {
        await axios.put(`${BaseUrl}/products/${id}`)
        .then (response => {
          //handleProductEdit(productId);
          //console.log('Deleted successfully!');
        })
        .catch (err => {
          //console.log('Error deleting Image');
        });
      };

    useEffect(() => {
        getItems();
    }, [data, theItem])
  return (
    <>
        <Breadcrumb title={props.title} />
        <Col className='d-flex flex-column justify-content-start border border-1 border-light p-3 my-2 bg-white rounded shadow-sm'>
            <div className="d-flex flex-row justify-content-between align-items-center border-bottom mb-4 px-1 py-2">
                <h2 className="text-bold accent-color fs-5 m-0 p-0"><ArrowLeft size={18} /> {props.title}</h2>
                <div name="buttons">
                    <Button variant="outline-warning" size="sm" className="text-bold" onClick={addNewForm}><Plus size={16} /> افزودن</Button>
                </div>
            </div>
            {
                data && !dataErr ?
                    data.length !== 0 ?
                    <UsersTable theads={['تصویر', 'عنوان', 'نام', 'نام خانوادگی', 'تلفن همراه', 'آدرس', 'وضعیت', 'عملیات']} data={data} deleteItem={deleteItem} editItem={editItem} />
                        :
                    <NoItemFound />
                :
                !data && !dataErr ?
                    <LoadingTable />
                :
                <Error title="خطا" message="خطایی در هنگام دریافت اطلاعات بوجود آمد! لطفا مجددا تلاش کنید..." />
            }
        </Col>
        {deleteModal ?
        <Modal show={showDeleteModal} onHide={closeDeleteModal} backdrop="static" keyboard={false} size='md' style={{width: '100vw'}} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='bg-danger text-white' size='sm' closeButton>
                <Modal.Title className='fs-6 text-bold'><AlertTriangle /> حذف آیتم</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    deleteError ? 
                    <>
                    <Error title="خطا" message="حذف این آیتم با خطا مواجه شد! لطفا مجددا تلاش کنید و در صورت تکرار خطا با ادمین تماس بگیرید." />
                    </>
                    :
                    ""
                }
                <div className='pt-3 pb-3'>آیا از حذف این آیتم اطمینان دارید؟</div>
            </Modal.Body>
            <Modal.Footer className='d-flex flex-row justify-content-between align-items-center'>
                    {loadingDelete ?
                    <>
                    <div className='mx-auto'>
                        <Spinner animation="grow"  variant='danger' role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                    </>
                    :
                    <>
                    <button type="button" className="btn btn-white btn-sm" onClick={closeDeleteModal}>
                        برگشت
                    </button>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => confirmDeleteItem(deleteId)}>
                    حذف
                    </button>
                    </>
                    }
            </Modal.Footer>
        </Modal>
        : ""}
        {addModal ?
        <Modal show={showAddModal} onHide={closeAddModal} backdrop="static" keyboard={false} size='md' style={{width: '100vw'}} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className='text-dark' size='sm' closeButton>
                <Modal.Title className='fs-6 text-bold'><Plus /> افزودن آیتم</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    addError ? 
                    <>
                    <Error title="خطا" message="افزودن آیتم جدید با خطا مواجه شد! لطفا مجددا تلاش کنید و درصورت تکرار این خطا با ادمین تماس بگیرید." />
                    </>
                    :
                    ""
                }
                <div className='pt-3 pb-3'>
                    <input type="text" id="title" className="form-control form-control-lg mb-3" placeholder="عنوان نمایندگی" />
                    <input type="text" id="firstName" className="form-control form-control-lg mb-3" placeholder="نام" />
                    <input type="text" id="lastName" className="form-control form-control-lg mb-3" placeholder="نام خانوادگی" />
                    <input type="text" id="mobile" className="form-control form-control-lg mb-3" placeholder="تلفن همراه" />
                    <input type="password" id="password" className="form-control form-control-lg mb-3" placeholder="رمز عبور" />
                    <input type="password" id="password_repeat" className="form-control form-control-lg mb-3" placeholder="تکرار رمز عبور" />
                    <input type="text" id="address" className="form-control form-control-lg mb-3" placeholder="آدرس" />
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex flex-row justify-content-between align-items-center'>
                    {loadingAdd ?
                    <>
                    <div className='mx-auto'>
                        <Spinner animation="grow"  variant='primary' role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                    </>
                    :
                    <>
                    <button type="button" className="btn btn-lg d-flex w-100 justify-content-center align-items-center btn-primary" onClick={() => confirmAddItem()}>
                    افزودن
                    </button>
                    </>
                    }
            </Modal.Footer>
        </Modal>
        : ""}
        {editModal? 
        <Modal show={showEditModal} onHide={closeEditModal} backdrop="static" keyboard={false} size='lg' style={{width: '100vw'}} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className='text-dark' size='sm' closeButton>
            <Modal.Title className='fs-6 text-bold'><Plus /> ویرایش آیتم</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                !theItem ?
                <>
                    Loading...
                </>
            :
            <>
            <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                    <div className="d-flex flex-row justify-content-start align-center flex-wrap"> 
                      <div className="d-flex flex-row w-25 p-1">
                        <img src={`${BaseUrl}/../${theItem.avatar.path}/${theItem.avatar.filename}`} alt={theItem.avatar._id} className="d-block w-100 mx-1 img-thumbnail" />
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center p-3 rounded border-dashed mt-3">
                      <img src={require('../../src/select-image.png')} alt="Product" className="d-block w-25 m-auto"  onClick={() => document.getElementById('photo').click()}/>
                      <input type="file" id="avatar" name="avatar" accept=".jpg, .png" onChange={photoUpload} hidden />
                      <button className="btn btn-primary btn-sm" name="photoUpload" onClick={() => document.getElementById('avatar').click()}>آپلود تصویر</button>
                    </div>
                    <div id="uploadError" className="d-none text-danger">خطائی پیش امده، مجددا تلاش کنید.</div>
                    <div id="progressParent" className="progress mt-2 d-none">
                      <div id="uploadProgress" className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </Col>
                <Col>
                    <Row>
                        <Col className="mb-3">
                            <label for="title" className="myform">
                                عنوان نمایندگی
                            </label>
                            <input id="title" class="form-control" type="text" defaultValue={theItem.title} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-3">
                            <label for="firstName" className="myform">
                                نام نماینده
                            </label>
                            <input id="firstName" class="form-control" type="text" defaultValue={theItem.firstName} />
                        </Col>
                        <Col className="mb-3">
                            <label for="lastName" className="myform">
                                نام خانوادگی نماینده
                            </label>
                            <input id="lastName" class="form-control" type="text" defaultValue={theItem.lastName} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-3">
                            <label for="mobile" className="myform">
                                تلفن همراه
                            </label>
                            <input id="mobile" class="form-control" type="text" defaultValue={theItem.mobile} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-3">
                            <label for="address" className="myform">
                                آدرس
                            </label>
                            <textarea id="address" class="form-control" type="text" defaultValue={theItem.address}>
                            </textarea>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </>
            }
        </Modal.Body>
        </Modal>
        : ""}
    </>
  )
}

export default Users